/* OrderExtra View, Vue Component */
<template>
  <v-card v-if="entity && !loading" id="orderextra-view" class="entity-view">
      <v-card-title>View Order Extra</v-card-title>

      <v-card-text class="d-flex align-center flex-wrap pb-0">
      <v-row class="align-center pb-5">
        <v-col>

        <v-btn
          color="primary"
          class="me-3"
          :to="{ name: 'orderextra-edit', params: { id: entity.Id } }"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiPencilOutline }}
          </v-icon>
          <span>Edit</span>
        </v-btn>
        </v-col>
        <v-col class="text-right">
        <v-btn
          color="secondary"
          class="me-3"
          :to="{ name: 'orderextra-create' }"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          <span>Create Order Extra</span>
        </v-btn>
        <v-btn
          color="secondary"
          class="me-3"
          :to="{ name: 'orderextra-list'}"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiViewList }}
          </v-icon>
          <span>Back</span>
        </v-btn>
        </v-col>
      </v-row>
      </v-card-text>

      <v-simple-table>
        <thead>
          <tr>
            <th>Field</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>

      <tr>
        <td>Name</td>
        <td>{{ entity.Name }}</td>
      </tr>

      <tr>
        <td>Price</td>
        <td>{{ Display.Money(entity.Price) }}</td>
      </tr>

      <tr>
        <td>Example</td>
        <td>{{ entity.Example }}</td>
      </tr>

      <tr>
        <td>Additional</td>
        <td><div v-html="additonalMarkdown"></div></td>
      </tr>

      <tr>
        <td>Order</td>
        <td>{{ entity.SortOrder }}</td>
      </tr>

      <tr>
        <td>Enabled</td>
        <td><v-icon size="18" :color="booleanIcons(entity.Enabled).variant">{{ booleanIcons(entity.Enabled).icon }}</v-icon></td>
      </tr>

      <tr>
        <td>Created</td>
        <td>{{ Display.DateTime(entity.Created) }}</td>
      </tr>

      <tr>
        <td>Updated</td>
        <td>{{ Display.DateTime(entity.Updated) }}</td>
      </tr>

        </tbody>
      </v-simple-table>

  </v-card>
</template>

<script>
import store from '@/store'
import { useRouter } from '@core/utils'
import * as Display from '@/datahelpers/displayTypes'
// import { marked } from 'marked';

import {
  mdiPencilOutline,
  mdiPlus,
  mdiViewList,
  mdiCheckboxBlankOutline,
  mdiCheckboxMarkedOutline,
  mdiClose
} from '@mdi/js'

import { ref, watch, computed } from '@vue/composition-api'

export default {
  setup() {

    const { route, router  } = useRouter();

    const entity = computed(() => store.state.app.OrderExtra);
    const loading = ref(false);

    const fetchData = (id) => {
      store
        .dispatch('app/fetchOrderExtra', {
          id: id
        })
        .then(response => {
          loading.value = false
        })
        .catch(error => {
          console.log(error);
          router.push({ name: 'orderextra-list'});
        })
    }

    // fetch the user information when params change
    //watch(() => route.value.params.id, (id) => fetchData(id));

    // and call now
    fetchData(route.value.params.id);


    const booleanIcons = status => {
      if (status) return { variant: 'success', icon: mdiCheckboxMarkedOutline }
      return { variant: 'secondary', icon: mdiCheckboxBlankOutline }
    }

    const additonalMarkdown = computed(() => {
      if (store.state.app.OrderExtra && store.state.app.OrderExtra.Additional) {
        // return marked(store.state.app.OrderExtra.Additional);
        return store.state.app.OrderExtra.Additional;
      }
      return "";
    });

    return {
      additonalMarkdown,
      loading,
      entity,
      Display,
      booleanIcons,
      icons: {
        mdiPencilOutline,
        mdiPlus,
        mdiViewList,
        mdiClose
      },
    };
  }
}
</script>

<style lang="scss" scoped>
.entity-view::v-deep .v-data-table th:last-child {
  width: 66%;
}
</style>

